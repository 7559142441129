import React, { useEffect, useState } from 'react';

import { RouteComponentProps, Router } from '@reach/router';

import AuthenticatedRoute from '@components/Auth/AuthenticatedRoute';
import RequireLogin from '@components/Auth/RequireLogin';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';
import DelegationHistory from '@presenters/web/pages/Delegation/History/DelegationHistory';
import DelegateRole from '@presenters/web/pages/Delegation/ManageDelegationRoles/DelegateRole';
import EditDelegation from '@presenters/web/pages/Delegation/ManageDelegationRoles/EditDelegation';
import DelegationRoles from '@presenters/web/pages/Delegation/Roles';
import getDelegationSubMenu from '@presenters/web/pages/Delegation/Utility/getDelegationSubMenu';

import { useUserAccount } from '@repositories/auth/hooks';
import { useFetchDelegationProfile } from '@repositories/delegation';

import { FEATURE_DELEGATION, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { PageName } from '@typings/delegation';

const Delegation: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix }, location }) => {
  const isDelegationEnabled = isEnabled(FEATURE_DELEGATION);
  const [isDelegationEligible, setIsDelegationEligible] = useState<Boolean>(
    false
  );
  const [userActionType, setUserActionType] = useState<
    'Add' | 'Edit' | 'Delete' | null
  >(null);
  const { t } = useTranslation();
  const clubSubMenu = getDelegationSubMenu(t);

  const { user } = useAppConfig();

  const individualIdForFetch = (user?.isLoggedIn && user.individualId) || null;
  const { data: userData } = useUserAccount(individualIdForFetch);

  useEffect(() => {
    if (userData?.getIndividual) {
      const eligibilityFound = userData?.getIndividual.clubAffiliations.some(
        clubAffilation =>
          clubAffilation.affiliations.some(
            member =>
              member.__typename === 'LeadershipAffiliation' ||
              member.__typename === 'MembershipAffiliation'
          )
      );

      if (eligibilityFound) {
        setIsDelegationEligible(true);
      }
    }
  }, [userData]);

  const riIndividualId = (user?.isLoggedIn && user.riIndividualId) || '';

  const { loading, data: delegationData } = useFetchDelegationProfile(
    riIndividualId || '',
    PageName.ROLE
  );

  if (loading) {
    return <Loading />;
  }

  if (isDelegationEnabled && !loading && !isDelegationEligible) {
    return <AccessDenied />;
  }

  return (
    <OneColumn className="mb-20">
      <div id="delegation-page">
        <Helmet
          titleTemplate={`${t('metadata.title.delegation', 'Delegation')} | ${t(
            'metadata.title.default',
            'My Rotary'
          )}`}
        />
        <div className="mb-5">
          <Title>{t('delegation.title', 'Delegation')}</Title>
        </div>
        <Submenu items={clubSubMenu} location={location} />
        <RequireLogin>
          <Router basepath={`/${languagePrefix}delegation`}>
            {isDelegationEnabled && isDelegationEligible && (
              <>
                <AuthenticatedRoute
                  path="/history"
                  Component={DelegationHistory}
                  riIndividualId={riIndividualId}
                />
                <AuthenticatedRoute
                  path="/roles"
                  Component={DelegationRoles}
                  delegationProfile={delegationData?.delegationProfile}
                  setUserActionType={setUserActionType}
                  userActionType={userActionType}
                  languagePrefix={languagePrefix}
                  default
                />
                <AuthenticatedRoute
                  path="/roles/:orgId/:orgType/:delegatorId/delegate-role/:roleId/:date"
                  Component={DelegateRole}
                  delegationRoles={delegationData?.delegationProfile?.Roles}
                  setUserActionType={setUserActionType}
                />
                <AuthenticatedRoute
                  path="/roles/edit-delegation/:roleId"
                  Component={EditDelegation}
                  delegationData={
                    delegationData?.delegationProfile?.Delegations
                  }
                  setUserActionType={setUserActionType}
                />
              </>
            )}
          </Router>
        </RequireLogin>
      </div>
    </OneColumn>
  );
};

export default Delegation;
