import {
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARY,
  DISTRICT_TYPE,
  REGIONAL_GROUP_TYPE,
} from '@domain/districts';

export const formatOrgType = (orgType: string = '') => {
  switch (orgType) {
    case 'Rotary Club':
      return CLUB_TYPE_ROTARY;
    case 'Rotaract Club':
      return CLUB_TYPE_ROTARACT;
    case 'District':
      return DISTRICT_TYPE;
    case 'Regional Group':
      return REGIONAL_GROUP_TYPE;
    default:
      return '';
  }
};
