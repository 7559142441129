import React, { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';

import { Tab, TabInfo } from '@components/TableWithTabs/types';

type Props = {
  activeTabInfo: TabInfo;
  tabs: Tab[];
  onChangeTab: Dispatch<SetStateAction<TabInfo>>;
};

const TableWithTabs: React.FC<Props> = ({
  activeTabInfo: { name: activeTabName, scrollTo },
  tabs,
  onChangeTab,
}) => {
  const activeTabObject = tabs.find((tab: Tab) => tab.name === activeTabName);
  const activeTabContent = activeTabObject?.component as React.ReactElement;

  if (!activeTabContent) {
    return null;
  }

  if (tabs.length === 1) {
    return activeTabContent;
  }

  const tableTitle = tabs.map(tab => (
    <div
      data-testid={tab.name}
      className={classNames(
        'font-bold uppercase tracking-wide text-sm mb-0 mr-6 py-6 whitespace-no-wrap desktop:whitespace-normal cursor-pointer',
        {
          'border-dark-blue-400 text-dark-blue-400 border-b-2':
            activeTabName === tab.name,
          'text-gray-300': activeTabName !== tab.name,
        }
      )}
      data-active-tab={tab.name}
      key={tab.name}
      onClick={() =>
        onChangeTab((prevState: TabInfo) => ({
          ...prevState,
          name: tab.name,
        }))
      }
    >
      {tab.title}
    </div>
  ));

  const injectedProps = {
    tableTitle,
    scrollToTab: scrollTo,
    setActiveTab: onChangeTab,
    summary: tableTitle,
    isHeaderPadded: false,
  };

  return (
    <activeTabContent.type {...activeTabContent.props} {...injectedProps} />
  );
};

export default TableWithTabs;
