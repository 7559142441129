import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';
import classNames from 'classnames';
import moment from 'moment';

import Icon from '@components/Icon';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import { getOrgTypeLocation } from '@utils/getOrgTypeLocation';
import { getOrgTypeName } from '@utils/getOrgTypeName';

import { useTranslation } from '@external/react-i18next';

import { DelegatedOfficerData } from '@typings/delegation';

interface MobileDelegationListProps {
  delegationData: DelegatedOfficerData[];
  actionButtons?: (delegateRole: DelegatedOfficerData) => {};
  editDeleteActionButtons?: (delegateRole: DelegatedOfficerData) => {};
  isDateRangeVisible?: boolean;
}
const MobileDelegationList: React.FC<MobileDelegationListProps> = ({
  delegationData,
  actionButtons,
  editDeleteActionButtons,
  isDateRangeVisible = false,
}) => {
  const { t } = useTranslation();
  const [expandedResult, setExpandedResult] = useState<string | null>(null);

  const handleCollapse = (uniqueIdentifier: string) => {
    if (expandedResult !== uniqueIdentifier) {
      setExpandedResult(uniqueIdentifier);
    } else {
      setExpandedResult(null);
    }
  };

  return (
    <ResultsList headerClassName="px-4 bg-gray-100">
      {delegationData.map((role, index) => {
        const uniqueIdentifier = `${role.individualId}-${index}`;
        const startDate = moment(new Date(role.startDate)).format(
          'DD MMM YYYY'
        );
        const endDate =
          role.endDate && moment(new Date(role.endDate)).format('DD MMM YYYY');

        const isExpanded = Boolean(expandedResult === uniqueIdentifier);
        return (
          <ResultsListItem key={uniqueIdentifier}>
            <div
              className={classNames(
                `member-row-mobile desktop:hidden w-full px-5`,
                {
                  'bg-bright-blue-100 pb-8 -my-4 pt-5': isExpanded,
                  'member-row-mobile--expanded': isExpanded,
                }
              )}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleCollapse(uniqueIdentifier)}
                className={classNames(
                  'relative w-full member-row-info flex items-center text-left text-xs font-normal leading-6 focus:shadow-none tap-highlight-color-transparent overflow-hidden'
                )}
                data-testid="toggle-expand-btn"
              >
                <div>
                  <p className="text-md leading-6 mb-0">{role.role}</p>
                  <p className="mb-0">
                    {role.orgType &&
                      role.location &&
                      `${getOrgTypeName(t, role.orgType)} ${getOrgTypeLocation(
                        role.orgType,
                        role.location
                      )}`}
                  </p>
                  {role.delegatedTo && (
                    <p className="mb-0">
                      {t(
                        'delegation.moblie-list.role.delegatedTo',
                        'Delegated to: {{ delegatedTo }}',
                        {
                          delegatedTo: role.delegatedTo,
                        }
                      )}
                    </p>
                  )}
                  {role.delegatedBy && (
                    <p className="mb-0">
                      {t(
                        'delegation.moblie-list.role.delegatedBy',
                        'Delegated by: {{ delegatedBy }}',
                        {
                          delegatedBy: role.delegatedBy,
                        }
                      )}
                    </p>
                  )}
                  {isDateRangeVisible && (
                    <p className="text-xs mb-0" data-cy="start-date">
                      {`${startDate} - ${endDate}`}
                    </p>
                  )}
                  <div
                    aria-hidden="true"
                    className={classNames(
                      'absolute right-0 absolute-vertical-center',
                      { hidden: isExpanded }
                    )}
                  >
                    <Icon name="arrows/down" size="17" color="black" />
                  </div>
                  <div
                    aria-hidden="true"
                    className={classNames('absolute right-0 top-1', {
                      hidden: !isExpanded,
                    })}
                  >
                    <Icon name="ui/close" size="17" color="black" />
                  </div>
                </div>
              </div>
              <Collapse isOpen={isExpanded} className="collapse-css-transition">
                <div className="w-full">
                  {!isDateRangeVisible && (
                    <p className="text-xs mb-0" data-cy="start-date">
                      {`${startDate} - ${endDate}`}
                    </p>
                  )}
                  {actionButtons?.(role) || editDeleteActionButtons?.(role)}
                </div>
              </Collapse>
            </div>
          </ResultsListItem>
        );
      })}
    </ResultsList>
  );
};

export default MobileDelegationList;
