import React from 'react';

import Modal from 'react-modal';

import { Button } from '@components/Button';
import Icon from '@components/Icon';

import tailwind from '@styles/tailwind.js';

interface Props {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  headingMessage?: string;
  confirmButtonLabel?: string;
  contentLabel?: string;
  modalDiv?: JSX.Element;
  cancelButtonLabel?: string;
}

const BaseModal: React.FC<Props> = ({
  isOpen = false,
  onConfirm: handleConfirm,
  onCancel: handleCancel,
  headingMessage,
  confirmButtonLabel,
  contentLabel,
  modalDiv,
  cancelButtonLabel,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      className="delete-conference-modal-mobile tablet:delete-conference-modal-desktop"
      ariaHideApp={false}
    >
      <div className="flex items-center justify-end">
        <Icon
          name="ui/close"
          color={tailwind.theme.colors.gray['300']}
          className="cursor-pointer"
          clickHandler={handleCancel}
        />
      </div>
      <div className="pt-5 tablet:pt-8 tablet:pr-6">
        <h2 className="text-xl leading-xl">{headingMessage}</h2>
        {modalDiv}
        <div className="mt-15 tablet:mr-6 flex flex-col tablet:flex-row items-center">
          <Button
            className="flex-1 mb-4 tablet:mr-8 tablet:mb-0"
            isMinWidth={false}
            clickHandler={handleConfirm}
          >
            {confirmButtonLabel}
          </Button>
          <Button
            secondary
            isMinWidth={false}
            className="flex-1"
            clickHandler={handleCancel}
          >
            {cancelButtonLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;
