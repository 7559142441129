import { useMutation } from '@apollo/client';

import { editDelegationMutation } from '../queries';

import {
  EditDelegationMutation,
  EditDelegationMutationVariables,
} from '@typings/operations';

export const useEditDelegation = () => {
  return useMutation<EditDelegationMutation, EditDelegationMutationVariables>(
    editDelegationMutation
  );
};
