import { TFunction } from 'i18next';

import { Drupal } from '@typings/drupal';

export default (t: TFunction): Array<Drupal.MenuItem> => {
  const items: Array<Drupal.MenuItem> = [];

  items.push(
    {
      id: '1',
      label: t('delegation-roles', 'Roles'),
      path: '/delegation/roles',
      language_prefix: 'en',
    },
    {
      id: '2',
      label: t('delegation-histroy', 'History'),
      path: '/delegation/history',
      language_prefix: 'en',
    }
  );

  return items;
};
