import React from 'react';

import classNames from 'classnames';

import Tooltip from '@components/Tooltip';

import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';

type Props = {
  toolTipSummary: string;
  toolTipContent: string;
  count: string;
};

const DelegationTooltip: React.FC<Props> = ({
  toolTipSummary,
  toolTipContent,
  count,
}) => {
  const isMobile = useScreenSizeLayout(ScreenSizes.mobile);
  const isMobileL = useScreenSizeLayout(ScreenSizes.mobileL);
  const isMobileM = useScreenSizeLayout(ScreenSizes.mobileM);
  const isTablet = useScreenSizeLayout(ScreenSizes.tablet);
  const isTabletL = useScreenSizeLayout(ScreenSizes.tabletL);
  const isMobileScreen = isMobile || isMobileM || isMobileL;
  const isTabletScreen = !isMobileScreen && (isTablet || isTabletL);
  const svgClassName = !(isMobileScreen || isTabletScreen) ? '-mb-2' : '';

  if (toolTipContent) {
    return (
      <>
        <span
          className={classNames('font-bold uppercase tracking-wide text-sm', {
            'px-5 py-2 bg-gray-100 block -mx-5': isMobileScreen,
            'px-4 py-2 bg-gray-100 block mx-0': isTabletScreen,
          })}
        >
          {toolTipSummary} ({count})
          {(isMobileScreen || isTabletScreen) && (
            <span className="font-normal normal-case tracking-normal">
              <Tooltip content={toolTipContent} svgClass={svgClassName} />
            </span>
          )}
        </span>
        {!(isMobileScreen || isTabletScreen) && (
          <Tooltip content={toolTipContent} svgClass={svgClassName} />
        )}
      </>
    );
  }

  return null;
};

export default DelegationTooltip;
