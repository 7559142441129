import React, { useCallback, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import moment from 'moment';

import { Button } from '@components/Button';
import Link from '@components/Link';
import Loading from '@components/Loading';
import BaseModal from '@presenters/web/components/BaseModal';
import DelegationList from '@presenters/web/components/DelegationList';
import MobileDelegationList from '@presenters/web/components/DelegationList/Mobile';
import DelegationTooltip from '@presenters/web/components/DelegationToolTip';

import { delegationMappedData } from '../Utility/delegationMappedData';

import {
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARY,
  DISTRICT_TYPE,
  REGIONAL_GROUP_TYPE,
} from '@domain/districts';

import {
  useDeleteDelegation,
  useFetchDelegationProfileLazy,
} from '@repositories/delegation';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { DelegatedOfficerData, PageName } from '@typings/delegation';
import { DIS2 } from '@typings/dis2';

interface DelegationRolesProps extends RouteComponentProps {
  delegationProfile: DIS2.DelegationProfile;
  setUserActionType: React.Dispatch<
    React.SetStateAction<'Add' | 'Edit' | 'Delete' | null>
  >;
  userActionType: 'Add' | 'Edit' | 'Delete' | null;
  languagePrefix: string;
}

const DelegationRoles: React.FC<DelegationRolesProps> = ({
  delegationProfile,
  setUserActionType,
  userActionType,
  languagePrefix,
}) => {
  const [delegationProfileData, setDelegationProfileData] = useState<
    DIS2.DelegationProfile
  >(delegationProfile);
  const { t } = useTranslation();
  const getUrl = new URL(window.location.href);
  const editButton = t('delegation-roles.edit', 'Edit');
  const delegateRoleButton = t(
    'delegation-roles.delegate-role',
    'Delegate role'
  );
  const removeDelegation = t(
    'delegation-roles.remove-delegation',
    'Remove delegation'
  );

  const cancelButtonLabel = t('action-Button.cancel', 'Cancel');

  const noRolesAvailableMsg = t(
    'no-roles-available-msg',
    'No roles available to delegate'
  );
  const noRolesDelegatedToMsg = t(
    'no-roles-delegated-to-msg',
    'You have no roles currently delegated'
  );
  const noRolesDelegatedByMsg = t(
    'no-roles-delegated-by-msg',
    'You have no roles delegated to you'
  );

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);

  const [
    selectedDelegateOfficerRole,
    setDelegateOfficerRole,
  ] = useState<DelegatedOfficerData | null>(null);

  const [
    fetchDelegationProfileLazy,
    { loading },
  ] = useFetchDelegationProfileLazy();

  const showModal = (
    event?: React.SyntheticEvent,
    delegateRole?: DelegatedOfficerData,
    isMobile?: boolean
  ) => {
    event?.preventDefault();
    setDelegateOfficerRole(delegateRole || null);
    setIsMobile(Boolean(isMobile));
    setIsRemoveModalOpen(true);
  };

  const { user } = useAppConfig();
  const riIndividualId = (user?.isLoggedIn && user.riIndividualId) || '';
  const individualId = (user?.isLoggedIn && user.individualId) || '';

  const { roleData, rolesDelegatedBy, rolesDelegatedTo } = delegationMappedData(
    delegationProfileData,
    riIndividualId,
    individualId
  );
  const DATE_FORMAT = 'DD MMM yyyy';

  useEffect(() => {
    if (userActionType !== null) {
      fetchDelegationProfileLazy({
        variables: {
          memberId: riIndividualId,
          page: PageName.ROLE,
        },
      }).then(res => {
        setDelegationProfileData(
          res?.data?.delegationProfile as DIS2.DelegationProfile
        );
        setUserActionType(null);
      });
    }
  }, [userActionType]);

  const handelCancel = () => {
    setIsRemoveModalOpen(false);
  };

  const [
    deleteDelegation,
    { loading: isdeleteDelegationLoading },
  ] = useDeleteDelegation();

  const removeDelegationMessage = t(
    'delgation.remove-officer.are-you-sure',
    'Are you sure you want to remove delegation?'
  );

  const className = isMobile ? 'text-lg' : 'text-base';

  const removeDelegationModalDiv = (
    <>
      <span className={className}>
        {t(
          'delgation.remove-officer.role',
          `{{ SelectedDelegateOfficerRole }} role in {{Location}}`,
          {
            SelectedDelegateOfficerRole: selectedDelegateOfficerRole?.role,
            Location: selectedDelegateOfficerRole?.location,
          }
        )}
      </span>
      <p className={`mb-0 ${className}`}>
        {t(
          'delgation.remove-officer.delegated-to',
          'delegated to {{ DelegatedTo }}',
          {
            DelegatedTo: selectedDelegateOfficerRole?.delegatedTo,
          }
        )}
      </p>
      <span className={className}>
        {t(
          'delgation.remove-officer.start-end-date',
          `Start Date {{StartDate}}, End Date {{EndDate}}`,
          {
            StartDate: moment(selectedDelegateOfficerRole?.startDate).format(
              DATE_FORMAT
            ),
            EndDate: moment(selectedDelegateOfficerRole?.endDate).format(
              DATE_FORMAT
            ),
          }
        )}
      </span>
    </>
  );

  const getOrgId = (
    delegator: DelegatedOfficerData,
    orgType?: string | null
  ) => {
    switch (orgType) {
      case CLUB_TYPE_ROTARY:
      case CLUB_TYPE_ROTARACT:
        return delegator.clubId;
      case DISTRICT_TYPE:
        return delegator.districtId;
      case REGIONAL_GROUP_TYPE:
        return delegator.regionalGroupId;
      default:
        return 'dummy-id';
    }
  };

  const generateNavUrl = (delegator: DelegatedOfficerData) => {
    const startDate = moment(new Date(delegator.startDate)).format(
      'YYYY-MM-DD'
    );
    const endDate = delegator.endDate
      ? moment(new Date(delegator.endDate)).format('YYYY-MM-DD')
      : '';
    const navigateUrl = `${getUrl.pathname}/${getOrgId(
      delegator,
      delegator.orgType
    )}/${delegator.orgType}/${delegator.individualId}/delegate-role/${
      delegator.roleId
    }/${startDate}_${endDate}`;
    return navigateUrl;
  };

  const handleDelegateRole = useCallback((delegator: DelegatedOfficerData) => {
    const navigateUrl = generateNavUrl(delegator);
    return (
      <Link
        to={navigateUrl}
        className="flex-auto text-xs mb-0 mr-3 desktop-l:ml-3"
      >
        {delegateRoleButton}
      </Link>
    );
  }, []);

  const generateEditPageUrl = (delegator: DelegatedOfficerData) => {
    const navigateEditPage = `${getUrl.pathname}/edit-delegation/${delegator.roleId}`;
    return navigateEditPage;
  };

  const handleEditDelegateRole = useCallback(
    (delegator: DelegatedOfficerData) => {
      const navigateUrl = generateEditPageUrl(delegator);
      return (
        <>
          <Link
            to={navigateUrl}
            className="flex-auto text-xs mb-0 mr-3 desktop-l:ml-3"
          >
            {editButton}
          </Link>
          {moment(new Date(delegator.startDate)).isAfter(
            moment(new Date())
          ) && (
            <a
              href=" "
              className="inline flex-auto text-xs mb-0 text-blue-400"
              onClick={e => showModal(e, delegator)}
            >
              {removeDelegation}
            </a>
          )}
        </>
      );
    },
    []
  );

  if (loading) {
    <Loading />;
  }
  if (isdeleteDelegationLoading) {
    return <Loading />;
  }

  const handleConfirmDeleteDelegation = async () => {
    await deleteDelegation({
      variables: {
        input: {
          memberId: selectedDelegateOfficerRole?.individualId || '',
          delegationKey: selectedDelegateOfficerRole?.delegationKey || '',
        },
      },
    });
    setUserActionType('Delete');
    setIsRemoveModalOpen(false);
  };

  return (
    <>
      <div className="text-base mt-6 tablet:mt-6">
        {t(
          'delegation-roles.description',
          'You can delegate temporary permission to conduct online Rotary business on your behalf to any individual in your club or district who has an active My Rotary account. You control the start and end date and can schedule multiple delegations at one time.'
        )}
        <br />
        <a
          className="text-bright-blue-600"
          target="_blank"
          rel="noreferrer"
          href={`https://my-cms.rotary.org/${languagePrefix}document/how-delegate-your-online-access`}
        >
          {t('delegation-roles.quick-guide', 'View the delegation quick guide')}
        </a>
      </div>
      <div className="tablet: mt-4 desktop:mt-4">
        <DelegationTooltip
          toolTipSummary={t('delegation-roles-your-roles', 'YOUR ROLES')}
          toolTipContent={t(
            'delegation-tooltip.your-roles',
            'These roles are available for you to delegate. You can only delegate a role to one person at a time during the duration of the delegation. Once you have delegated a role, it will appear in the next column.'
          )}
          count={roleData.length.toString()}
        />
        <div className="hidden desktop:block mt-2">
          {roleData?.length ? (
            <DelegationList
              delegationData={roleData}
              isActionsButtonVisible
              isDelegateColumnVisible={false}
              assignActionButtons={handleDelegateRole}
            />
          ) : (
            noRolesAvailableMsg
          )}
        </div>
        <div className="desktop:hidden mobile:-mx-5 tablet:mx-0">
          {roleData?.length ? (
            <MobileDelegationList
              delegationData={roleData}
              isDateRangeVisible
              actionButtons={(delegateRole: DelegatedOfficerData) => {
                return (
                  <Button
                    small
                    type="button"
                    data-testid="delegateRole"
                    className="mt-5 tablet:w-full"
                    clickHandler={() => navigate(generateNavUrl(delegateRole))}
                  >
                    {delegateRoleButton}
                  </Button>
                );
              }}
            />
          ) : (
            <div className="mobile:mx-5 tablet:mx-4">{noRolesAvailableMsg}</div>
          )}
        </div>
        <div className="mt-10">
          <DelegationTooltip
            toolTipSummary={t(
              'delegation-roles.delegated-to',
              'ROLES YOU HAVE DELEGATED'
            )}
            toolTipContent={t(
              'delegation-tooltip.delegated-to',
              'These roles are available for you to delegate. You can only delegate a role to one person at a time during the duration of the delegation. Once you have delegated a role, it will appear in the next column.'
            )}
            count={rolesDelegatedTo.length.toString()}
          />
          <div className="hidden desktop:block mt-2">
            {rolesDelegatedTo?.length ? (
              <DelegationList
                delegationData={rolesDelegatedTo}
                isActionsButtonVisible
                editDeleteActionButtons={handleEditDelegateRole}
              />
            ) : (
              noRolesDelegatedToMsg
            )}
          </div>
          <div className="desktop:hidden mobile:-mx-5 tablet:mx-0">
            {rolesDelegatedTo?.length ? (
              <MobileDelegationList
                delegationData={rolesDelegatedTo}
                editDeleteActionButtons={(
                  delegateRole: DelegatedOfficerData
                ) => {
                  return (
                    <>
                      <Button
                        small
                        type="button"
                        data-testid="editButton"
                        className="mt-5 tablet:w-full"
                        clickHandler={() =>
                          navigate(generateEditPageUrl(delegateRole))
                        }
                      >
                        {editButton}
                      </Button>
                      {moment(new Date(delegateRole.startDate)).isAfter(
                        moment(new Date())
                      ) && (
                        <button
                          type="button"
                          data-testid="removeDelegation"
                          className="inline-block tracking-wide w-full transition ease-in-out duration-150 font-bold uppercase justify-center text-center text-bright-blue-600 hover:underline focus:underline active:text-dark-blue-400 text-2xs mt-8"
                          onClick={e => showModal(e, delegateRole, true)}
                        >
                          {removeDelegation}
                        </button>
                      )}
                    </>
                  );
                }}
              />
            ) : (
              <div className="mobile:mx-5 tablet:mx-4">
                {noRolesDelegatedToMsg}
              </div>
            )}
          </div>
        </div>
        <div className="mt-10">
          <DelegationTooltip
            toolTipSummary={t(
              'delegation-roles-delegated-you',
              'ROLES DELEGATED TO YOU'
            )}
            toolTipContent={t(
              'delegation-tooltip.delegated-you',
              'These roles are available for you to delegate. You can only delegate a role to one person at a time during the duration of the delegation. Once you have delegated a role, it will appear in the next column.'
            )}
            count={rolesDelegatedBy.length.toString()}
          />
          <div className="hidden desktop:block mt-2">
            {rolesDelegatedBy?.length ? (
              <DelegationList
                delegationData={rolesDelegatedBy}
                delegatedType={false}
                isActionsButtonVisible
                assignActionButtons={undefined}
              />
            ) : (
              noRolesDelegatedByMsg
            )}
          </div>
          <div className="desktop:hidden mobile:-mx-5 tablet:mx-0">
            {rolesDelegatedBy?.length ? (
              <MobileDelegationList delegationData={rolesDelegatedBy} />
            ) : (
              <div className="mobile:mx-5 tablet:mx-4">
                {noRolesDelegatedByMsg}
              </div>
            )}
          </div>
        </div>
      </div>
      <BaseModal
        headingMessage={removeDelegationMessage}
        isOpen={isRemoveModalOpen}
        onCancel={handelCancel}
        onConfirm={handleConfirmDeleteDelegation}
        confirmButtonLabel={removeDelegation}
        contentLabel="Remove Delegation modal"
        modalDiv={removeDelegationModalDiv}
        cancelButtonLabel={cancelButtonLabel}
      />
    </>
  );
};

export default DelegationRoles;
