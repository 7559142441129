import { gql } from '@apollo/client';

export const fetchDelegationProfile = gql`
  query getDelegationProfile($memberId: String!, $page: String!) {
    delegationProfile: getDelegationProfile(memberId: $memberId, page: $page) {
      Delegates {
        DelegatorMemberId
        DelegatorFullName
        MemberId
        Role
        OrganizationId
        OrganizationName
        OrganizationType
        RoleStartDate
        RoleEndDate
        DelegationStartDate
        DelegationEndDate
        Key
      }
      Delegations {
        DelegateeMemberId
        DelegateeFullName
        MemberId
        Role
        OrganizationId
        OrganizationName
        OrganizationType
        RoleStartDate
        RoleEndDate
        DelegationStartDate
        DelegationEndDate
        Key
      }
      Roles {
        MemberId
        FirstName
        MiddleName
        LastName
        RoleKey
        Role
        OrganizationId
        Organization
        OrganizationType
        Committee
        CommitteeType
        StartDate
        EndDate
        IsDelegationEligible
        Location {
          State
          InternationalProvince
          Country
        }
        TerminationReason
        Key
        LastUpdated
      }
    }
  }
`;
