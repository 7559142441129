import { useLazyQuery, useQuery } from '@apollo/client';

import { fetchDelegationProfile } from '@repositories/delegation';

import {
  GetDelegationProfileQuery,
  GetDelegationProfileQueryVariables,
} from '@typings/operations';

export const useFetchDelegationProfile = (memberId: string, page: string) =>
  useQuery<GetDelegationProfileQuery, GetDelegationProfileQueryVariables>(
    fetchDelegationProfile,
    {
      variables: { memberId, page },
      skip: (memberId || '') === '',
    }
  );

export const useFetchDelegationProfileLazy = () =>
  useLazyQuery<GetDelegationProfileQuery, GetDelegationProfileQueryVariables>(
    fetchDelegationProfile
  );
