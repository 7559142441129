import { gql } from '@apollo/client';

export const fetchRegionalGroupDistricts = gql`
  query getRegionalGroupDistrictsId($regionalGroupId: String!) {
    regionalGroupDistricts: getRegionalGroupDistricts(
      regionalGroupId: $regionalGroupId
    ) {
      DistrictId
    }
  }
`;
