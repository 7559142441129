import { formatOrgType } from './formatOrgType';
import { sortRoleByDateAsc } from './sortUtilities';

import { DelegatedOfficerData } from '@typings/delegation';
import { DIS2 } from '@typings/dis2';

export const mapDelegationProfileToOfficer = (
  delegationProfileData: DIS2.DelegationProfileData[],
  riIndividualId?: string,
  individualId?: string
) => {
  if (delegationProfileData?.length) {
    return delegationProfileData?.length
      ? sortRoleByDateAsc(
          delegationProfileData?.map((delegationProfile, index) => {
            return {
              individualId: individualId || delegationProfile.MemberId,
              memberId:
                riIndividualId ||
                delegationProfile.DelegateeMemberId ||
                delegationProfile.DelegatorMemberId ||
                '',
              role: delegationProfile.Role,
              location:
                delegationProfile.Organization ||
                delegationProfile.OrganizationName ||
                null,
              delegatedTo: delegationProfile.DelegateeFullName || '',
              delegatedBy: delegationProfile.DelegatorFullName || '',
              startDate:
                delegationProfile.StartDate ||
                delegationProfile.DelegationStartDate,
              endDate:
                delegationProfile.EndDate ||
                delegationProfile.DelegationEndDate,
              roleId: `${delegationProfile.Key}${index}`,
              orgId: delegationProfile.OrganizationId,
              orgType: delegationProfile?.OrganizationType
                ? formatOrgType(delegationProfile?.OrganizationType)
                : null,
              clubId:
                delegationProfile.OrganizationType === 'Rotary Club' ||
                delegationProfile.OrganizationType === 'Rotaract Club'
                  ? delegationProfile.OrganizationId
                  : null,
              districtId:
                delegationProfile.OrganizationType === 'District'
                  ? delegationProfile.OrganizationId
                  : null,
              regionalGroupId:
                delegationProfile.OrganizationType === 'Regional Group'
                  ? delegationProfile.OrganizationId
                  : null,
              delegationKey: delegationProfile.Key || null,
            };
          })
        )
      : [];
  }
  return [];
};

export const delegationMappedData = (
  delegationProfile: DIS2.DelegationProfile,
  riIndividualId: string,
  individualId: string
) => {
  let roleData: DelegatedOfficerData[] = [];
  let rolesDelegatedTo: DelegatedOfficerData[] = [];
  let rolesDelegatedBy: DelegatedOfficerData[] = [];
  if (delegationProfile?.Roles?.length) {
    roleData = mapDelegationProfileToOfficer(
      delegationProfile.Roles as DIS2.DelegationProfileData[],
      riIndividualId,
      individualId
    );
  }
  if (delegationProfile?.Delegations?.length) {
    rolesDelegatedTo = mapDelegationProfileToOfficer(
      delegationProfile.Delegations as DIS2.DelegationProfileData[]
    );
  }
  if (delegationProfile?.Delegates?.length) {
    rolesDelegatedBy = mapDelegationProfileToOfficer(
      delegationProfile.Delegates as DIS2.DelegationProfileData[]
    );
  }
  return {
    roleData,
    rolesDelegatedTo,
    rolesDelegatedBy,
  };
};
