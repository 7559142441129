import React from 'react';

import moment from 'moment';

import { ResultsList, ResultsListItem } from '@components/ResultsList';

import { getOrgTypeLocation } from '@utils/getOrgTypeLocation';
import { getOrgTypeName } from '@utils/getOrgTypeName';

import { useTranslation } from '@external/react-i18next';
import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';

import { DelegatedOfficerData } from '@typings/delegation';

interface DelegationListProps {
  delegationData: DelegatedOfficerData[];
  delegatedType?: boolean;
  assignActionButtons?: (delegateRole: DelegatedOfficerData) => {};
  editDeleteActionButtons?: (delegateRole: DelegatedOfficerData) => {};
  isActionsButtonVisible?: boolean;
  isDelegateColumnVisible?: boolean;
}
const DelegationList: React.FC<DelegationListProps> = ({
  delegationData,
  delegatedType = true,
  assignActionButtons,
  editDeleteActionButtons,
  isActionsButtonVisible = false,
  isDelegateColumnVisible = true,
}) => {
  const { t } = useTranslation();
  const delegatedMenu = delegatedType
    ? t('delegation.table-header.history.delegated-to', 'Delegated to')
    : t('delegation.table-header.history.delegated-by', 'Delegated by');

  const isDesktopSmall = useScreenSizeLayout(ScreenSizes.desktopS);

  const flexClassFn = (
    isActionsButtonVisible: boolean,
    isDelegateColumnVisible: boolean,
    isDesktopSmall: boolean
  ) => {
    if (isDesktopSmall && !isActionsButtonVisible) {
      return 'flex-1';
    }
    if (isActionsButtonVisible && !isDelegateColumnVisible) {
      return 'flex-3';
    }
    if (isActionsButtonVisible) {
      return 'desktop-l:flex-3 flex-2 desktop-m:flex-3';
    }
    return 'flex-2';
  };
  return (
    <ResultsList className="mt-1">
      <ResultsListItem
        spacingY={4}
        borderBottomClass=""
        className="bg-gray-100 table-header"
      >
        <div className="flex-wrap items-center flex-1 flex font-bold text-small text-gray-400">
          <div
            className={`flex flex-col self-center ${flexClassFn(
              isActionsButtonVisible,
              isDelegateColumnVisible,
              isDesktopSmall
            )} text-xs leading-6 w-16 ml-4 mr-4`}
          >
            {t('delegation.table-header.history.role', 'Role')}
          </div>
          <div
            className={`flex flex-col self-center ${
              !isDelegateColumnVisible ? 'flex-3' : 'flex-2'
            } text-xs leading-6 w-16 ml-10 mr-4`}
          >
            {t('delegation.table-header.history.location', 'Location')}
          </div>
          {isDelegateColumnVisible && (
            <div
              className={`flex flex-col self-center desktop-custom-delegation1:flex-2 ${flexClassFn(
                isActionsButtonVisible,
                isDelegateColumnVisible,
                false
              )} text-xs leading-6 w-16 mr-4`}
            >
              {delegatedMenu}
            </div>
          )}
          {!isDelegateColumnVisible && (
            <div className="flex flex-col self-center flex-2 desktop-custom-delegation1:flex-1 text-xs leading-6 w-16 mr-4">
              {' '}
            </div>
          )}
          <div
            className={`flex flex-col self-center flex-1 ${
              isActionsButtonVisible ? 'tracking-widest ' : ''
            } text-xs leading-6 w-16 mr-4`}
          >
            {t('delegation.table-header.history.start-date', 'Start Date')}
          </div>
          <div
            className={`flex flex-col self-center flex-1 ${
              isActionsButtonVisible ? 'tracking-widest' : ''
            } text-xs leading-6 w-16 mr-4`}
          >
            {t('delegation.table-header.history.end-date', 'End Date')}
          </div>
          {isActionsButtonVisible && (
            <div className="flex-3 desktop-l:flex-2 desktop-custom-delegation1:flex-2">
              {' '}
            </div>
          )}
        </div>
      </ResultsListItem>
      {delegationData.map((role, index) => {
        const uniqueIdentifier = `${role?.individualId}-${index}`;

        return (
          <ResultsListItem
            spacingY={4}
            key={uniqueIdentifier}
            className="table-header"
          >
            <div className="hidden tablet:flex flex-1 items-center text-xs leading-xs">
              <div
                className={`flex flex-col self-center ${flexClassFn(
                  isActionsButtonVisible,
                  isDelegateColumnVisible,
                  isDesktopSmall
                )} text-xs leading-6 w-16 ml-4 mr-4`}
              >
                {role.role}
              </div>
              <div
                className={`flex flex-col self-center ${
                  !isDelegateColumnVisible ? 'flex-3' : 'flex-2'
                } text-xs leading-6 w-16 ml-10 mr-4`}
              >
                {role.orgType &&
                  role.location &&
                  `${getOrgTypeName(t, role.orgType)} ${getOrgTypeLocation(
                    role.orgType,
                    role.location
                  )}`}
              </div>
              {isDelegateColumnVisible && (
                <div
                  className={`flex flex-col self-center desktop-custom-delegation1:flex-2 ${flexClassFn(
                    isActionsButtonVisible,
                    isDelegateColumnVisible,
                    false
                  )} text-xs leading-6 w-16 mr-4`}
                >
                  {role.delegatedTo || role.delegatedBy}
                </div>
              )}
              {!isDelegateColumnVisible && (
                <div className="flex flex-col self-center flex-2 desktop-custom-delegation1:flex-1 text-xs leading-6 w-16 mr-4">
                  {' '}
                </div>
              )}
              <div
                className={`flex flex-col self-center flex-1 ${
                  isActionsButtonVisible
                    ? 'desktop-custom-delegation2:tracking-widest desktop-custom-delegation3:tracking-widest'
                    : ''
                }  text-xs leading-6 w-16 mr-4`}
              >
                {moment(new Date(role.startDate)).format('DD MMM YYYY')}
              </div>
              <div className="flex flex-col self-center flex-1 text-xs leading-6 w-16 mr-4">
                {role.endDate &&
                  moment(new Date(role.endDate)).format('DD MMM YYYY')}
              </div>
              {isActionsButtonVisible && (
                <div className="flex-3 desktop-l:flex-2 desktop-custom-delegation1:flex-2">
                  {' '}
                  {assignActionButtons?.(role) ||
                    editDeleteActionButtons?.(role)}
                </div>
              )}
            </div>
          </ResultsListItem>
        );
      })}
    </ResultsList>
  );
};
export default DelegationList;
