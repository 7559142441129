import { useLazyQuery } from '@apollo/client';

import { fetchRegionalGroupDistricts } from '@repositories/delegation';

import {
  GetRegionalGroupDistrictsIdQuery,
  GetRegionalGroupDistrictsIdQueryVariables,
} from '@typings/operations';

export const useFetchRegionalGroupDistricts = () =>
  useLazyQuery<
    GetRegionalGroupDistrictsIdQuery,
    GetRegionalGroupDistrictsIdQueryVariables
  >(fetchRegionalGroupDistricts);
