export type DelegatedOfficerData = {
  individualId: string;
  memberId?: string;
  role: string;
  location: string | null;
  delegatedTo?: string;
  delegatedBy?: string;
  startDate: string;
  endDate?: string | null;
  clubId?: string | null;
  districtId?: string | null;
  regionalGroupId?: string | null;
  roleId?: string;
  orgType?: string | null;
  delegationKey?: string | null;
};

export type DelegateDetailTable = {
  individualId: string;
  orgType: string;
  location: string;
  role: string;
  delegatedTo: string;
  startDate: string;
  endDate: string;
};

export enum PageName {
  ROLE = 'role',
  HISTORY = 'history',
}
