import React, { useState } from 'react';

import { useField } from 'formik';
import { toNumber } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import { WizardPage } from '@components/Formik/Wizard';
import LabelWithValue from '@components/LabelWithValue';
import Loading from '@components/Loading';
import Title from '@components/Title';
import CheckboxPrompt from '@presenters/web/components/CheckboxPrompt';

import { Individual, Period } from '@domain/clubs/types';
import { DelegateRoleValues } from '@domain/delegation';

import { useTouchedFormFields } from '@use-cases/clubs';
import { useNotifications } from '@use-cases/notifications';

import { useAddDelegation } from '@repositories/delegation/hooks/useAddDelegation';

import { getRotaryYear, isFutureRY } from '@utils/datetime';
import { disLanguageCodeToUiLanguageCode } from '@utils/languages';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const ConfirmOfficer: WizardPage<DelegateRoleValues> = ({
  proceed,
  handleFormFieldsTouched,
  step,
  setStep,
  handleCancelBtnClick,
  confirmationModal,
}) => {
  const { t } = useTranslation();
  const [delegatee] = useField<Individual>('delegatee');
  const [roleKey] = useField<string>('roleKey');
  const [roleName] = useField<string>('roleName');
  const [orgName] = useField<string>('orgName');
  const [orgType] = useField<string>('orgType');
  const [slot] = useField<Period>('slot');
  const [email] = useField<string>('email');

  const [customStart] = useField<Date | null>('customStart');
  const [customEnd] = useField<Date | null>('customEnd');

  const { user } = useAppConfig();

  const [
    addDelegation,
    {
      error: isAddDelegationError,
      called: isAddDelegationCalled,
      loading: isAddDelegationLoading,
    },
  ] = useAddDelegation();

  const { addError } = useNotifications();

  const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';
  const DATE_FORMAT = 'YYYY-MM-DD';
  const PAYLOAD_DATE_FORMAT = 'MM/DD/YYYY';

  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);

  const initialConfirmDelegateeValues = {
    email: delegatee?.value ? delegatee.value.email || '' : '',
    customStart: null,
    customEnd: null,
  };
  const delegatorRiIndividualId =
    (user?.isLoggedIn && user.riIndividualId) || '';

  let delegateeRiIndividualId = delegatee?.value
    ? delegatee.value.riIndividualId || ''
    : '';

  if (delegateeRiIndividualId === '0') {
    delegateeRiIndividualId = delegatee?.value.id;
  }

  const selectedValues = {
    email: email.value,
    customStart: customStart.value,
    customEnd: customEnd.value,
  };

  const { start: startDate, end: endDate } = slot.value;

  checkTouchedFields(initialConfirmDelegateeValues, selectedValues);

  const formatedRoleStartDate = moment(startDate).format(DATE_FORMAT);

  const rotaryYear = toNumber(getRotaryYear(formatedRoleStartDate));

  const isFutureRotaryYear = isFutureRY(rotaryYear);

  const startDateLabel = t('delegate-role.start-date', 'Start Date*');

  const endDateLabel = t('delegate-role.end-date', 'End Date*');

  const delegateeOrgType =
    orgType.value === 'Rotaract Club' ? 'Rotaract' : 'Rotary';

  const handleConfirmButtonClick = async () => {
    const payload = {
      roleKey: roleKey.value,
      startDate: moment(customStart.value).format(PAYLOAD_DATE_FORMAT),
      endDate: moment(customEnd.value).format(PAYLOAD_DATE_FORMAT),
      delegateeMemberId: delegateeRiIndividualId,
      memberId: delegatorRiIndividualId,
      delegateeEmail: delegatee.value.email || '',
      delegateePrefLangCode:
        disLanguageCodeToUiLanguageCode[
          delegatee.value.preferredLanguage || ''
        ] || '',
      delegateeOrgTypeName: delegateeOrgType,
      delegatedRole: roleName.value,
    };
    try {
      await addDelegation({
        variables: {
          input: {
            ...payload,
          },
        },
      });
      proceed();
    } catch (error) {
      if (isAddDelegationError) {
        addError((error as Error).message);
      }
    }
  };

  const getMinEndDate = (isFutureRotaryYear: boolean) => {
    if (isFutureRotaryYear) {
      return moment(startDate.toDate()).toDate();
    }
    return moment().toDate();
  };

  const customStartDateCalendarValues = {
    name: 'customStart',
    label: t('delegate-role.custom-start-date-label', 'Custom Start Date'),
    minDate: isFutureRotaryYear ? startDate.toDate() : moment().toDate(),
    maxDate: customEnd.value
      ? moment(customEnd.value).toDate()
      : moment(endDate.toDate()).toDate(),
  };

  const customEndDateCalendarValues = {
    name: 'customEnd',
    label: t('delegate-role.custom-end-date-label', 'Custom End Date'),
    minDate: customStart.value
      ? moment(customStart.value).toDate()
      : getMinEndDate(isFutureRotaryYear),
    maxDate: moment(endDate.toDate()).toDate(),
  };

  const [isTermsConfirmed, setIsTermsConfirmed] = useState(false);

  const tPrompt = t(
    'delegate-role.terms-of-delegation',
    '<p><b class="text-xs font-bold">Terms of Delegation*</b><br><span class="text-md">I understand that I am temporarily delegating some of my authority and responsibility to another individual. I understand that I will continue to be responsible for the duties delegated, and that the delegation will continue until I revoke it.</span></p>'
  );

  if (isAddDelegationLoading && isAddDelegationCalled) {
    return <Loading />;
  }

  return (
    <>
      <Title className="h2 desktop:mb-4 desktop:mt-7 mobile:mt-4 mobile:mb-1 mobile:font-normal desktop:font-light">
        {t('delegate-role.confirm-role', 'Confirm a role')}
      </Title>

      <Title className="h3 desktop:mb-7 mobile:mb-4">
        {t(
          'delegate-role.role-description',
          '{{roleName}} in {{orgType}} {{orgName}}',
          {
            roleName: roleName.value,
            orgType: orgType.value,
            orgName: orgName.value,
          }
        )}
      </Title>

      <div className="max-w-lg desktop:mb-4 mobile:mb-3">
        <LabelWithValue
          label={t('delegate-role.role-delegated-to', 'Role delegated to')}
          value={delegatee.value?.name}
        />

        <div className="custom-datepicker w-45 relative mb-4">
          <p className="desktop:mb-4 text-xs font-bold tablet:mb-2 mobile:mb-2">
            {startDateLabel}
          </p>
          <Calendar
            label={customStartDateCalendarValues.label}
            labelHidden
            name={customStartDateCalendarValues.name}
            minDate={customStartDateCalendarValues.minDate}
            maxDate={customStartDateCalendarValues.maxDate}
            dateFormat={CALENDAR_DATE_FORMAT}
          />
        </div>

        <div className="custom-datepicker w-45 relative mb-6">
          <p className="desktop:mb-4 text-xs font-bold tablet:mb-2 mobile:mb-2">
            {endDateLabel}
          </p>
          <Calendar
            label={customEndDateCalendarValues.label}
            labelHidden
            name={customEndDateCalendarValues.name}
            minDate={customEndDateCalendarValues.minDate}
            maxDate={customEndDateCalendarValues.maxDate}
            dateFormat={CALENDAR_DATE_FORMAT}
          />
        </div>

        <div className="flex flex-col w-full">
          <CheckboxPrompt
            isChecked={isTermsConfirmed}
            toggleIsChecked={() => setIsTermsConfirmed(!isTermsConfirmed)}
            prompt={tPrompt}
            mobileTextLeft
          />
        </div>

        <div className="flex flex-col desktop:mt-13 mobile:mt-12">
          <Button
            full
            type="button"
            clickHandler={handleConfirmButtonClick}
            disabled={
              !customStart.value || !customEnd.value || !isTermsConfirmed
            }
          >
            {t('delegate-role.save', 'SAVE')}
          </Button>
          <Button clickHandler={handleCancelBtnClick} text className="mt-8">
            {t('delegate-role.cancel', 'CANCEL')}
          </Button>
          {confirmationModal?.(step, setStep)}
        </div>
      </div>
    </>
  );
};

export default ConfirmOfficer;
