import {
  REGIONAL_GROUP_TYPE,
  REGIONAL_GROUP_TYPE_DIS,
} from '@domain/districts';

import { Maybe } from '@typings/graphql';

export const getOrgTypeLocation = (
  type: Maybe<string> | undefined,
  location: string
): string => {
  switch (type) {
    case REGIONAL_GROUP_TYPE:
    case REGIONAL_GROUP_TYPE_DIS:
      return location.split(' ')[1];
    default:
      return location;
  }
};
