import { useMutation } from '@apollo/client';

import { addDelegation } from '../queries';

import {
  AddDelegationMutation,
  AddDelegationMutationVariables,
} from '@typings/operations';

export const useAddDelegation = () => {
  return useMutation<AddDelegationMutation, AddDelegationMutationVariables>(
    addDelegation
  );
};
