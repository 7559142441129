import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import ReactDatePicker from 'react-datepicker';

import { Icon } from '../Icon';
import Element, { ElementProps } from './Element';

import { useTranslation } from '@external/react-i18next';

export interface DateProps extends ElementProps {
  placeholder?: string;
  defaultDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  forceSetTouched?: boolean;
  showError?: boolean;
  showDefaultDate?: boolean;
  dateFormat?: string;
  initialDateValue?: Date;
}

const Calendar: React.FC<DateProps> = props => {
  const {
    name,
    forceSetTouched,
    disabled,
    minDate,
    maxDate,
    placeholder,
    showDefaultDate = false,
    dateFormat = 'yyyy-MM-dd',
    initialDateValue,
  } = props;

  const { t } = useTranslation();
  const [field, meta, helpers] = useField<Date>(name);
  const defaultDate = new Date();

  useEffect(() => {
    if (showDefaultDate) {
      helpers.setValue(defaultDate);
    }
    if (initialDateValue) {
      helpers.setValue(initialDateValue);
    }
  }, []);

  const handleChange = (date: Date) => {
    helpers.setValue(date);

    if (forceSetTouched) {
      setTimeout(() => {
        helpers.setTouched(true);
      }, 0);
    }
  };

  return (
    <Element {...props}>
      <ReactDatePicker
        id={name}
        className={classNames(
          'border border-gray-300 focus:border-dark-blue-400 rounded-sm w-full py-3 px-4 text-xs leading-loose',
          'focus-visible:outline-0 focus:shadow-none',
          {
            'bg-gray-100 cursor-not-allowed placeholder-gray-300': disabled,
            'placeholder-gray-400': !disabled,
            'bg-red-100 border-red-600': !!meta.error && meta.touched,
          }
        )}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        selected={showDefaultDate ? field.value || defaultDate : field.value}
        todayButton={t('forms.datepicker.today-label', 'Select today')}
        disabled={disabled}
        onChange={handleChange}
      />
      <div className="absolute bottom-0 h-6 w-6 m-auto mr-4 right-0 top-0 pointer-events-none">
        <Icon name="ui/calendar" color="gray-400" />
      </div>
    </Element>
  );
};

export default Calendar;
