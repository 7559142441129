import { useMutation } from '@apollo/client';

import { deleteDelegationMutation } from '../queries';

import {
  DeleteDelegationMutation,
  DeleteDelegationMutationVariables,
} from '@typings/operations';

export const useDeleteDelegation = () => {
  return useMutation<
    DeleteDelegationMutation,
    DeleteDelegationMutationVariables
  >(deleteDelegationMutation);
};
