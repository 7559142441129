import React from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import DelegationList from '@presenters/web/components/DelegationList';
import MobileDelegationList from '@presenters/web/components/DelegationList/Mobile';
import DelegationTooltip from '@presenters/web/components/DelegationToolTip';

import { formatOrgType } from '../Utility/formatOrgType';
import { sortByDateDes } from '../Utility/sortUtilities';

import { useFetchDelegationProfile } from '@repositories/delegation';

import { useTranslation } from '@external/react-i18next';

import { DelegateDetailTable, PageName } from '@typings/delegation';

interface DelegationHistoryProps extends RouteComponentProps {
  riIndividualId: string;
}

const DelegationHistory: React.FC<DelegationHistoryProps> = ({
  riIndividualId,
}) => {
  const { t } = useTranslation();
  const { loading, data: delegationData } = useFetchDelegationProfile(
    riIndividualId || '',
    PageName.HISTORY
  );

  const noDelegationHistoryFoundMsg = t(
    'no-delegation-history-found-msg',
    'No delegations were found within this time period'
  );

  const delegationsTableData = delegationData?.delegationProfile?.Delegations
    ?.length
    ? delegationData.delegationProfile?.Delegations?.map(data => {
        return data
          ? {
              individualId: data.DelegateeMemberId,
              orgType: formatOrgType(data.OrganizationType as string),
              location: data.OrganizationName,
              role: data.Role,
              delegatedTo: data.DelegateeFullName,
              startDate: data.DelegationStartDate,
              endDate: data.DelegationEndDate,
            }
          : [];
      })
    : [];

  const delegatesTableData = delegationData?.delegationProfile?.Delegates
    ?.length
    ? delegationData?.delegationProfile?.Delegates?.map(data => {
        return data
          ? {
              individualId: data.DelegatorMemberId,
              orgType: formatOrgType(data.OrganizationType as string),
              location: data.OrganizationName,
              role: data.Role,
              delegatedTo: data.DelegatorFullName,
              startDate: data.DelegationStartDate,
              endDate: data.DelegationEndDate,
            }
          : [];
      })
    : [];

  if (loading) {
    <Loading />;
  }

  return (
    <div className="tablet:mt-10 desktop:mt-10">
      <DelegationTooltip
        toolTipSummary={t(
          'delegation-history-role.delegated-to',
          'Roles You Have Delegated'
        )}
        toolTipContent={t(
          'delegation-tooltip.content',
          'View a three year history of roles that you have delegated to others and roles that have been delegated to you.'
        )}
        count={delegationsTableData?.length.toString() || ''}
      />
      <div className="hidden desktop:block mt-2">
        {delegationsTableData?.length ? (
          <DelegationList
            delegationData={sortByDateDes(
              delegationsTableData as DelegateDetailTable[]
            )}
          />
        ) : (
          noDelegationHistoryFoundMsg
        )}
      </div>
      <div className="desktop:hidden mobile:-mx-5 tablet:mx-0">
        {delegationsTableData?.length ? (
          <MobileDelegationList
            delegationData={sortByDateDes(
              delegationsTableData as DelegateDetailTable[]
            )}
          />
        ) : (
          <div className="mobile:mx-5 tablet:mx-4">
            {noDelegationHistoryFoundMsg}
          </div>
        )}
      </div>
      <div className="mt-10">
        <DelegationTooltip
          toolTipSummary={t(
            'delegation-history-role.delegated-you',
            'Roles Delegated To YOU'
          )}
          toolTipContent={t(
            'delegation-tooltip.content',
            'View a three year history of roles that you have delegated to others and roles that have been delegated to you.'
          )}
          count={delegatesTableData?.length.toString() || ''}
        />
        <div className="hidden desktop:block mt-2">
          {delegatesTableData?.length ? (
            <DelegationList
              delegationData={sortByDateDes(
                delegatesTableData as DelegateDetailTable[]
              )}
              delegatedType={false}
            />
          ) : (
            noDelegationHistoryFoundMsg
          )}
        </div>
        <div className="desktop:hidden mobile:-mx-5 tablet:mx-0">
          {delegatesTableData?.length ? (
            <MobileDelegationList
              delegationData={sortByDateDes(
                delegatesTableData as DelegateDetailTable[]
              )}
            />
          ) : (
            <div className="mobile:mx-5 tablet:mx-4">
              {noDelegationHistoryFoundMsg}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DelegationHistory;
