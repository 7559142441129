import { TFunction } from 'i18next';

import {
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARY,
  DISTRICT_TYPE,
  REGIONAL_GROUP_TYPE,
} from '@domain/districts';

import { Maybe } from '@typings/graphql';

export const getOrgTypeName = (
  t: TFunction,
  type: Maybe<string> | undefined
): string => {
  switch (type) {
    case CLUB_TYPE_ROTARY:
      return t('club-type.rotary', 'Rotary Club');
    case CLUB_TYPE_ROTARACT:
      return t('club-type.rotaract', 'Rotaract Club');
    case DISTRICT_TYPE:
      return t('district-type.district', 'District');
    case REGIONAL_GROUP_TYPE:
      return t('regional-group-type.region', 'Region');
    default:
      return '';
  }
};
