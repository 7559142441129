import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { toNumber } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import LabelWithValue from '@components/LabelWithValue';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';
import CheckboxPrompt from '@presenters/web/components/CheckboxPrompt';

import {
  getBackButtonLabel,
  getDelegationRolesPath,
  useTouchedFormFields,
} from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';

import { useEditDelegation } from '@repositories/delegation';
import { useFetchIndividualInformation } from '@repositories/profile/hooks';

import { getRotaryYear, isFutureRY } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { getOrgTypeName } from '@utils/getOrgTypeName';
import { disLanguageCodeToUiLanguageCode } from '@utils/languages';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DIS2 } from '@typings/dis2';

interface EditDelegationProps extends RouteComponentProps {
  roleId: string;
  delegationData: DIS2.Delegations[];
  setUserActionType: React.Dispatch<
    React.SetStateAction<'Add' | 'Edit' | 'Delete' | null>
  >;
}

interface FormValues {
  customStart: string;
  customEnd: string;
}

const EditDelegation: React.FC<EditDelegationProps> = ({
  roleId,
  delegationData,
  setUserActionType,
}) => {
  const { t } = useTranslation();
  const [isTermsConfirmed, setIsTermsConfirmed] = useState(false);
  const [, setIsFieldTouched] = useState<boolean>(false);

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setTimeout(() => {
      setIsFieldTouched(isTouched);
    }, 0);
  };

  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);

  const initialValues: FormValues = {
    customStart: '',
    customEnd: '',
  };

  const initialConfirmDelegateeValues = {
    email: '',
    customStart: null,
    customEnd: null,
  };

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalOnConfirmHandler = () => {
    localizedNavigate(getDelegationRolesPath());
  };

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
  };

  const editedDelegation = delegationData?.find(
    (data, index) => `${data.Key}${index}` === roleId
  );

  const delegateeMemberId = Number(editedDelegation?.DelegateeMemberId);
  const {
    data: individualInformation,
    loading: individualInformationLoading,
  } = useFetchIndividualInformation(delegateeMemberId);

  const [
    editDelegation,
    { loading: isEditDelegationLoading },
  ] = useEditDelegation();

  if (isEditDelegationLoading || individualInformationLoading) {
    return <Loading />;
  }

  const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';
  const DATE_FORMAT = 'YYYY-MM-DD';
  const API_DATE_FORMAT = 'MM/DD/YYYY';

  const startDate = editedDelegation?.DelegationStartDate
    ? moment(new Date(editedDelegation?.DelegationStartDate)).format(
        DATE_FORMAT
      )
    : '';
  const endDate = editedDelegation?.DelegationEndDate
    ? moment(new Date(editedDelegation?.DelegationEndDate)).format(DATE_FORMAT)
    : '';
  const formatedRoleStartDate = startDate
    ? moment(moment(startDate)).format(DATE_FORMAT)
    : '';
  const rotaryYear = toNumber(getRotaryYear(formatedRoleStartDate));
  const isFutureRotaryYear = isFutureRY(rotaryYear);

  const startDateLabel = t('delegate-role.start-date', 'Start Date*');
  const endDateLabel = t('delegate-role.end-date', 'End Date*');
  const tPrompt = t(
    'delegate-role.terms-of-delegation',
    '<p><b class="text-xs font-bold">Terms of Delegation*</b><br><span class="text-md">I understand that I am temporarily delegating some of my authority and responsibility to another individual. I understand that I will continue to be responsible for the duties delegated, and that the delegation will continue until I revoke it.</span></p>'
  );

  const delegateeOrgType =
    editedDelegation?.OrganizationType === 'Rotaract Club'
      ? 'Rotaract'
      : 'Rotary';

  const handleSubmit = async (values: FormValues) => {
    window.scrollTo(0, 0);
    await editDelegation({
      variables: {
        input: {
          memberId: editedDelegation?.MemberId || '',
          delegationKey: editedDelegation?.Key || '',
          startDate: moment(values.customStart).format(API_DATE_FORMAT) || '',
          endDate: moment(values.customEnd).format(API_DATE_FORMAT) || '',
          delegateeMemberId: editedDelegation?.DelegateeMemberId || '',
          delegateeEmail:
            individualInformation?.individual.primaryEmail?.address || '',
          delegateePrefLangCode:
            disLanguageCodeToUiLanguageCode[
              individualInformation?.individual.preferredLanguage || ''
            ] || '',
          delegateeOrgTypeName: delegateeOrgType,
          delegatedRole: editedDelegation?.Role || '',
          delegateeIndividualId: individualInformation?.individual.id || '',
        },
      },
    });
    setUserActionType('Edit');
    localizedNavigate(getDelegationRolesPath());
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        label={getBackButtonLabel(t)}
        showModal={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />

      <Title className="h2 desktop:mb-4 desktop:mt-7 mobile:mt-4 mobile:mb-1 mobile:font-normal desktop:font-light">
        {t('delegate-role.edit-delegation', 'Edit Delegation')}
      </Title>

      <Title className="h3 desktop:mb-7 mobile:mb-4">
        {t(
          'delegate-role.role-description',
          '{{roleName}} in {{orgType}} {{orgName}}',
          {
            roleName: getClubRoleTValue(t, editedDelegation?.Role || ''),
            orgType: getOrgTypeName(t, editedDelegation?.OrganizationType),
            orgName: editedDelegation?.OrganizationName,
          }
        )}
      </Title>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }) => {
          const selectedValues = {
            email: '',
            customStart: values.customStart,
            customEnd: values.customEnd,
          };
          const customStartDateCalendarValues = {
            name: 'customStart',
            label: t(
              'delegate-role.custom-start-date-label',
              'Custom Start Date'
            ),
            minDate: isFutureRotaryYear
              ? moment(`${rotaryYear - 1}-07-01`).toDate()
              : moment().toDate(),
            maxDate: selectedValues.customEnd
              ? moment(selectedValues.customEnd).toDate()
              : moment(`${rotaryYear}-06-30`).toDate(),
          };

          const customEndDateCalendarValues = {
            name: 'customEnd',
            label: t('delegate-role.custom-end-date-label', 'Custom End Date'),
            minDate:
              selectedValues.customStart &&
              moment() <= moment(selectedValues.customStart)
                ? moment(selectedValues.customStart).toDate()
                : moment().toDate(),
            maxDate: moment(`${rotaryYear}-06-30`).toDate(),
          };
          const currentDate = moment(moment().format(DATE_FORMAT));
          checkTouchedFields(initialConfirmDelegateeValues, selectedValues);
          return (
            <Form>
              <div className="max-w-lg desktop:mb-4 mobile:mb-3">
                <LabelWithValue
                  label={t(
                    'delegate-role.role-delegated-to',
                    'Role delegated to'
                  )}
                  value={editedDelegation?.DelegateeFullName}
                />

                <div className="custom-datepicker w-45 relative mb-4">
                  <p className="desktop:mb-4 text-xs font-bold tablet:mb-2 mobile:mb-2">
                    {startDateLabel}
                  </p>
                  <Calendar
                    label={customStartDateCalendarValues.label}
                    labelHidden
                    name={customStartDateCalendarValues.name}
                    minDate={customStartDateCalendarValues.minDate}
                    maxDate={customStartDateCalendarValues.maxDate}
                    dateFormat={CALENDAR_DATE_FORMAT}
                    initialDateValue={moment(startDate).toDate()}
                    disabled={moment(startDate).isSameOrBefore(currentDate)}
                  />
                </div>
                {moment(startDate).isSameOrBefore(currentDate) && (
                  <div>
                    <p className="text-xs -mt-4 text-gray-400">
                      {t(
                        'delegate-role.validation.start-date',
                        'This delegation has already started. The start date cannot be changed.'
                      )}
                    </p>
                  </div>
                )}
                <div className="custom-datepicker w-45 relative mb-6 mt-4">
                  <p className="desktop:mb-4 text-xs font-bold tablet:mb-2 mobile:mb-2">
                    {endDateLabel}
                  </p>
                  <Calendar
                    label={customEndDateCalendarValues.label}
                    labelHidden
                    name={customEndDateCalendarValues.name}
                    minDate={customEndDateCalendarValues.minDate}
                    maxDate={customEndDateCalendarValues.maxDate}
                    dateFormat={CALENDAR_DATE_FORMAT}
                    initialDateValue={moment(endDate).toDate()}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <CheckboxPrompt
                    isChecked={isTermsConfirmed}
                    toggleIsChecked={() =>
                      setIsTermsConfirmed(!isTermsConfirmed)
                    }
                    prompt={tPrompt}
                    mobileTextLeft
                  />
                </div>

                <div className="flex flex-col desktop:mt-13 mobile:mt-12">
                  <Button
                    full
                    disabled={
                      (moment(startDate).toISOString() ===
                        moment(selectedValues.customStart).toISOString() &&
                        moment(endDate).toISOString() ===
                          moment(selectedValues.customEnd).toISOString()) ||
                      !isTermsConfirmed
                    }
                  >
                    {t('delegate-role.save', 'SAVE')}
                  </Button>
                  <Button
                    clickHandler={handleCancelBtnClick}
                    text
                    className="mt-8"
                  >
                    {t('delegate-role.cancel', 'CANCEL')}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditDelegation;
