import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { hasMaxLength, schemaField } from '../validation/schemaDefinitions';

export const searchFieldSchema = (t: TFunction, maxLength?: number) =>
  schemaField(t, Yup.string(), hasMaxLength(maxLength || 200));

const validationSchema = (t: TFunction) =>
  Yup.object({
    name: searchFieldSchema(t),
    email: searchFieldSchema(t),
  });

export default validationSchema;
