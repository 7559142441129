import { DelegateDetailTable, DelegatedOfficerData } from '@typings/delegation';

export const sortByDateDes = (delegationTableData: DelegateDetailTable[]) => {
  return delegationTableData.sort(
    (a, b) => +new Date(b.startDate) - +new Date(a.startDate)
  );
};

export const sortRoleByDateAsc = (roleData: DelegatedOfficerData[]) => {
  return roleData.sort(
    (a, b) => +new Date(a.startDate) - +new Date(b.startDate)
  );
};
