// Libs
import React, { ReactNode } from 'react';
import classnames from 'classnames';

type Props = {
  label: string;
  value?: string | ReactNode | null;
  isBold?: boolean;
};

const LabelWithValue: React.FC<Props> = ({ label, value, isBold = true }) => (
  <div className="mb-6 tablet:mb-4" data-testid="label-with-value">
    <div className={classnames('text-xs', { 'font-bold': isBold })}>
      {label}
    </div>
    <div className="text-md">{value}</div>
  </div>
);

export default LabelWithValue;
